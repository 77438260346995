<template>
  <div class="d-flex flex-column flex-shrink-0 p-3 text-white bg-menu" id="sidebar-wrapper">
    <router-link to="/profile" class="d-flex flex-column align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none w-100 px-4 text-center">
      <img v-if="$auth.user()" :src="$auth.user().image" :alt="$auth.user().name" class="rounded-circle mx-auto sb_profile_img" style="object-fit: cover">
      <span class="fs-4 text-uppercase fw-bold">{{ $auth.user().name }}</span>
    </router-link>
    <hr>
    <ul class="nav nav-pills flex-column">
      <li class="nav-item">
        <router-link :to="{name:'Classes'}" class="nav-link d-flex text-white fw-bold text-uppercase align-items-center" :exact-active-class="'active'">
          <i class="fas fa-calendar-star me-3"></i>
          <span>Classes</span>
        </router-link>
      </li>
<!--      <li class="nav-item">-->
<!--        <router-link :to="{name:'ReleaseCalendar'}" class="nav-link d-flex text-white fw-bold text-uppercase align-items-center" :exact-active-class="'active'">-->
<!--          <i class="fa fa-calendar-alt me-3"></i>-->
<!--          <span>Release Calendar</span>-->
<!--        </router-link>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <router-link :to="{name:'Classes'}" class="nav-link d-flex text-white fw-bold text-uppercase align-items-center" :active-class="'active'">-->
<!--          <i class="fa fa-list me-3"></i>-->
<!--          <span>Manage Classes</span>-->
<!--        </router-link>-->
<!--      </li>-->
    </ul>
    <button class="btn btn-primary mb-auto  mt-4 mx-3 text-uppercase rounded-pill py-2 fw-bold" @click="$router.push({name:'CreateClass'})">Create New Class</button>
    <ul class="nav nav-pills flex-column mt-auto">
      <li>
        <a @click.prevent="logout" href="#" class="nav-link text-white">
          <i class="fa fa-sign-out-alt"/>
            Logout
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  methods: {
    logout() {
      this.$auth
          .logout({
            makeRequest: true,
            redirect: {name: 'Login'},
          });
    }
  },
  mounted() {
      // Toggle the side navigation
    const sidebarToggle = document.body.querySelector('#sidebarToggle');
    if (sidebarToggle) {
      // Uncomment Below to persist sidebar toggle between refreshes
      // if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
      //     document.body.classList.toggle('sb-sidenav-toggled');
      // }
      sidebarToggle.addEventListener('click', event => {
        event.preventDefault();
        document.body.classList.toggle('sb-sidenav-toggled');
        localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
      });
    }
  }
}
</script>

<style scoped>

.sb_profile_img{
  width: 80px;
  height: 80px;
}
</style>