<template>
  <div class="h-100 main" v-if="$auth.ready()" id="wrapper">
    <sidebar></sidebar>
    <div class="content" id="page-content-wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar";
export default {
  name: "App",
  components: {Sidebar}
}
</script>

<style scoped>
.main {
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  max-height: 100vh;
  overflow-x: auto;
  overflow-y: hidden;
}
.content {
  flex-grow: 1;
  padding: 0.5rem 2rem
}
</style>