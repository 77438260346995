import Noty from 'noty'

const defaults = {
    layout: 'topRight',
    theme: 'bootstrap-v4',
    timeout: 3000,
    progressBar: true,
    closeWith: ['click'],
    callbacks: {
        onHover: function() {
            this.close()
        },
    }
}

const VueNoty = {
    options: {},

    setOptions (opts) {
        this.options = Object.assign({}, defaults, opts)
        return this
    },

    show (text, type = 'alert', opts = {}) {
        const params = Object.assign({}, this.options, opts, {
            type,
            text
        })

        return new Noty(params).show()
    },

    success (text, opts = {}) {
        return this.show(text, 'success', opts)
    },

    error (text, opts = {}) {
        return this.show(text, 'error', opts)
    },

    warning (text, opts = {}) {
        return this.show(text, 'warning', opts)
    },

    info (text, opts = {}) {
        return this.show(text, 'info', opts)
    },

    alert (text, opts = {}) {
        return this.show(text, 'alert', opts)
    }
}

export default {
    install: function (Vue, opts) {
        const noty = VueNoty.setOptions(opts)
        Vue.prototype.$noty = noty
        Vue.noty = noty
    }
}
