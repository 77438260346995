
export default {

    request: function (req, token) {
        this.drivers.http.setHeaders.call(this, req, {
            Authorization: 'Bearer ' + token,
        });
    },

    response: function (res) {
        var token   = (res.data && res.data.access_token) ? res.data.access_token : null ;
        if (token) {
            return token
        }
    }
};