import Vue from 'vue'

import auth from './auth';
import driverAuthBearer from './bearer';
import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x.js';
// import driverHttpVueResource from '@websanova/vue-auth/src/drivers/http/vue-resource.1.x.js';
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x.js';
import driverOAuth2Google from '@websanova/vue-auth/src/drivers/oauth2/google.js';
import driverOAuth2Facebook from '@websanova/vue-auth/src/drivers/oauth2/facebook.js';

driverOAuth2Google.params.client_id =  process.env.VUE_APP_API_GOOGLE;
driverOAuth2Facebook.params.client_id = process.env.VUE_APP_API_FACEBOOK;

Vue.use(auth, {
    plugins: {
        http: Vue.axios, // Axios
        // http: Vue.http, // Vue Resource
        router: Vue.router,
    },
    drivers: {
        auth: driverAuthBearer,
        http: driverHttpAxios, // Axios
        // http: driverHttpVueResource, // Vue Resource
        router: driverRouterVueRouter,
        oauth2: {
            google: driverOAuth2Google,
            facebook: driverOAuth2Facebook,
        }
    },
    options: {
        rolesKey: 'type',
        notFoundRedirect: {path: '/'},
        stores: ['cookie'],
        cookie: {
            Path:     '/',
            Domain:   null,
            Secure:   true,
            Expires:  12096e5,
            SameSite: 'None',
        },
        register: {
            redirect: '/login',
            autoLogin: false
        },
        fetchData: {
            url: 'auth/me'
        },
        refreshData: {
            url: 'auth/me',
            enabled: false,
        },
        auth: {
            redirect: '/login',
        },
        oauth2Data: {
            url: 'auth/login/social',
        }
    }
});